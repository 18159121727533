import React from 'react';
import {
  Alert, Col, PageHeader, Row, Space, Typography,
} from 'antd';
import { BaseLayout } from '../../components/layout/base/base-layout';
import { SEO } from '../../components/layout/seo';
import { Testimonials } from '../../components/layout/sections/testimonials/testimonials';
import { useShop } from '../../graphql/static-hooks/useShop';

const {
  Title,
  Paragraph,
} = Typography;

const TermsPage = () => {
  const shop = useShop();

  return (
    <BaseLayout>
      <SEO title={`Meet ${shop.name}`} />
      <Row align="center">
        <Col flex="650px">
          <PageHeader
            ghost={false}
            className="text-center"
          >
            <Space direction="vertical" size={32}>
              <Title level={2}>
                Meet
                {' '}
                {shop.name}
              </Title>
              <Title level={5}>
                {shop.name}
                {' '}
                is a new tool built to help event professionals connect with quality event service
                providers.
              </Title>
              <Paragraph>
                For nearly 20 years clients have relied on
                {' '}
                {shop.name}
                {' '}
                to help them find quality, trusted event
                services in cities all over the world.
                {' '}
                {shop.name}
                {' '}
                now puts the power of that search into your
                hands.
              </Paragraph>

              <Alert
                message={(
                  <Paragraph>
                    {shop.name}
                    {' '}
                    is in beta, so please take a look around! If you find something that needs
                    improvement, let us know and we’ll do our best to fix it and improve your
                    experience.
                  </Paragraph>
                )}
                type="info"
              />
            </Space>
          </PageHeader>
        </Col>
      </Row>
      <Testimonials />
    </BaseLayout>
  );
};

export default TermsPage;
